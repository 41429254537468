import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from "../utils/index"

Vue.use(VueRouter);
// const originalPush=VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//     return originalPush.call(this,location).cath(err => err)
// }
let originPush=VueRouter.prototype.push;
VueRouter.prototype.push=function(location,resolve,reject){
   if(resolve && reject){
      //如果成功 调用原来的push方法  
           originPush.call(this,location,resolve,reject); 
               }else{
           originPush.call(this,location,()=>{},()=>{}); }    
  }

  


const routes = [
  {
    path: '/homeview',
    name: 'homeview',
    component: () => import('../views/HomeView.vue'),
    children:[
      {path:'home',name:'home',component: () => import('../views/Home.vue'),meta:{requireAuth:true}},
      {path:'user',name:'user',component: () => import('../views/User.vue')},
      {path:'alread',name:'alread',component: () => import('../views/AlreadyRead.vue')},
      {path:'unread',name:'unread',component: () => import('../views/UnRead.vue')},
      {path:'showMsgDetail',name:'showMsgDetail',component: () => import('../views/ShowMsgDetail')}

    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },{
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },{
    path: '/findPass',
    name: 'findPass',
    component: () => import('../views/FindPass.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue')
  },
 {
    path: '/',
    name: 'Front',
    component: () => import('../views/front/Front'),
    redirect:'/mainHome',
    children: [
      {
        path: 'mainHome',
        name: 'FrontHome',
        component: () => import('../views/front/MainHome.vue')
      },
      {
        path:'personal',
        name:'personal',
        component:()=>import ('../views/front/Personal.vue'),
         children:[
          {
            path:'/profile',
            name:'profile',
            component:()=>import ('../views/front/profile'),
          },
          {
            path:'/cretificate',
            name:'cretificate',
            component:()=>import ('../views/front/cretificate'),
          },
          {
            path:'/orderManagement',
            name:'orderManagement',
            component:()=>import ('../views/front/orderManagement'),
          },
        ] 
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: () => import('../views/front/AboutUs.vue')
      },
      {
        path: 'payfor',
        name: 'payfor',
        component: () => import('../views/front/PayFor.vue')
      },
      {
        path:'payment',
        name:'payment',
        component:()=>import('../views/front/Payment.vue')
      },
      {
        path:'payoff',
        name:'payoff',
        component:()=>import('../views/front/PayOff.vue')
      },
      {
        path:'pay',
        name:'pay',
        component:()=>import('../views/front/Pay.vue')
      },
      {
        path:'payfordetail',
        name:'payfordetail',
        component:()=>import('../views/front/PayforDetail.vue'),
      },
      {
        path: 'companyD',
        name: 'companyD',
        component: () => import('../views/front/CompanyDynamic.vue')
      },
      {
        path: 'supportAndService',
        name: 'supportAndService',
        component: () => import('../views/front/SupportAndService.vue')
      },
      {
        path: 'solvePlan',
        name: 'solvePlan',
        component: () => import('../views/front/SolvePlan.vue')
      },
      {
        path: 'chainlist',
        name: 'chainlist',
        component: () => import('../views/front/Chainlist.vue')
      },
      {
        path: 'zsfProduct',
        name: 'ZsfProduct',
        component: () => import('../views/front/ZsfProduct.vue')
      },
      {
        path: 'zsfProductDetail',
        name: 'zsfProductDetail',
        component: () => import('../views/front/ZsfProductDetail.vue'),
        meta:{requireAuth:true}
      },
      {
        path: 'orderDetail',
        name: 'orderDetail',
        component: () => import('../views/front/OrderDetail.vue'),
        meta:{requireAuth:true}
      },
      {
        path: 'scjgProduct',
        name: 'scjgProduct',
        component: () => import('../views/front/ScjgProduct.vue')
      },
      {
        path: 'scjgProductDetail',
        name: 'scjgProductDetail',
        component: () => import('../views/front/ScjgProductDetail.vue')
      },
      {
        path: 'ylczProduct',
        name: 'ylczProduct',
        component: () => import('../views/front/YlczProduct.vue')
      },
      {
        path: 'ylczProductDetail',
        name: 'ylczProductDetail',
        component: () => import('../views/front/YlczProductDetail.vue')
      },
      {
        path: 'gylsyProduct',
        name: 'gylsyProduct',
        component: () => import('../views/front/GylsyProduct.vue')
      },
      {
        path: 'gylsyProductDetail',
        name: 'gylsyProductDetail',
        component: () => import('../views/front/GylsyProductDetail.vue')
      },
      {
        path: 'cunzhengPlatform',
        name: 'cunzhengPlatform',
        component: () => import('../views/front/CunZhengPlatform')
      },
      {
        path: 'newOne',
        name: 'newOne',
        component: () => import('../views/front/NewOne')
      },
      
      {
        path: 'accopent',
        name: 'accopent',
        component: () => import('../views/front/Accopent')
      },
      {
        path: 'firstCompany',
        name: 'firstCompany',
        component: () => import('../views/front/FirstCompany')
      },
      {
        path:'secondCompy',
        name:'secondCompy',
        component:()=>import('../views/front/SecondCompy')
      },
      {
        path: 'thirdCompany',
        name: 'thirdCompany',
        component: () => import('../views/front/ThirdCompany')
      },
      {
        path: 'newTwo',
        name: 'newTwo',
        component: () => import('../views/front/NewTwo')
      },
      {
        path: 'newThree',
        name: 'newThree',
        component: () => import('../views/front/NewThree')
      },{
        path:'e-sign',
        name:'e-sign',
        component:()=>import ('../views/front/e-sgin'),
      },{
        path:'sign',
        name:'sign',
        component:()=>import ('../views/front/Sign'),
      },{
        path:'electric',
        name:'electric',
        component:()=>import ('../views/front/Electric'),
      },{
        path:'package',
        name:'package',
        component:()=>import ('../views/front/Package'),
      },
      {
        path: 'searchCertificate',
        name: 'searchCertificate',
        component: () => import('../views/front/SearchCertificate')
      },
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}



// router.beforeEach((to,from,next)=>{
//   // 获取存储的token的值  有的话 赋值 isLogin 为true 否则 false
//     const isLogin=storage.getItem('token')?true:false; 
//     console.log(isLogin,'ppppp')
//     if(to.path=="/login"){
      
//       storage.removeItem('token');
//       storage.removeItem('user');
//       next();
//     }else{
//       isLogin?next():next('/login');
//     }
// })




// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
//     console.log(localStorage.getItem('token'))
//     if(localStorage.getItem('user')){ //判断本地是否存在access_token
//       next();
//     }else {
//       if(to.path === '/login'){
//         next();
//       }if(to.path === '/cunzhengPlatform'){
//         next({
//           path:'/mainHome'
//         })
//       }else {
//         alert('请先进行登录！')
//         next({
//           path:'/login'
//         })
//       }
//     }
//   }
//   else {
//     next();
//   }
//   /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
//   if(to.fullPath == "/login"){
//     if(localStorage.getItem('user')){
//       alert('您已经登录了，如果想要登录其他账号，请先退出当前账号！')
//       next({
//         path:from.fullPath
//       });
//     }else {
//       next();
//     }
//   }
// });


import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "@/utils/request";
import './assets/gloable.css';
import store from './store'
import 'animate.css';
import './assets/iconfont/iconfont.css'
import Plugin from 'v-fit-columns';


Vue.config.productionTip = false
Vue.use(ElementUI, { size: "mini" });
Vue.use(Plugin);

Vue.prototype.$http=axios
Vue.prototype.dateTypeFormat = function(fmt, date) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




import axios from 'axios'
import router from "@/router";
import {serverIp} from "../../public/config";
import storage from "../utils/index"
import {localStorageSet,localStorageGet, } from './utils'

var ws='ws://192.168.0.254:8980';
//var baseURL=`http://${serverIp}:9090`;
// var baseURL='https://super.gjjteam.top/admin'
//var baseURL =    'https://super.gjjteam.top/front'  //地址
//var baseURL = 'http://127.0.0.1:8282/front'  //地址
var baseURL = 'https://hczcloud.xinfushenghuo.cn/front'  //地址
axios.defaults.timeout = 30 * 1000
axios.defaults.baseURL = baseURL
 axios.defaults.retry = 3
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
axios.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
   // let user = storage.getItem("userInfo") 
    let user = localStorageGet('userInfo')
    console.log(user);
    if (user) {
        config.headers['x-access-token'] = `Bearer `+ localStorageGet('token') // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

/* request1.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    if (user) {
        config.headers['x-access-token'] = `Bearer `+user.token;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
}); */
// response 拦截器
// 可以在接口响应后统一处理结果
axios.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        // 当权限验证不通过的时候给出提示
        if (res.code === '401') {
            // ElementUI.Message({
            //     message: res.msg,
            //     type: 'error'
            // });
            router.push("/login")
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default axios





//设置缓存
const localStorageSet = (name, data) => {
  const obj = {
      data,
      expire: new Date().getTime() + 1000 * 60 * 30
  };
  localStorage.setItem(name, JSON.stringify(obj));
};
//读取缓存
const localStorageGet = name => {
  const storage = localStorage.getItem(name);
  const time = new Date().getTime();
  let result = null;
  if (storage) {
      const obj = JSON.parse(storage);
      if (time < obj.expire) {
          result = obj.data;
      } else {
          localStorage.removeItem(name);
      }
  }
  return result;
};
//时间戳转时间   
 const timeStampTotime = (date)=>{ 
  var t = new Date(date).toLocaleString(); 
  return t; 
}
module.exports = {
  
  localStorageSet,
  localStorageGet,
  timeStampTotime
}